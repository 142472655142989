import "../component/src/ui/avatar/style.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../component/src/ui/avatar/style.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VW7W7aMBT936ewNE2iEmb5/nD/7E0mkzhwV2Mj24Gyqe8+2QkQhzRrq6JKDcY35/ice3LJz2d2ahTdMY1+hUEYNUqH6O8DQsF3d0HIyD1BOEm+P7klZ40hCIdhvzaKCt1ItSNoR42ClwUOVlG5RDhYldkSBasycYs4XaJgiYJHe9/rA0JhdqY4YxZnTEeZzlLksUPNSkuRdRR5PqKIoxFF7jEkwRxDmTvQKLYMYdYpKkcMSTEWkXoUcTlHURQWOyk6Ke5/MSLIkntLKIK79yEM7h2n14eft0mO/CT3XHGwf/FadF1Pkffd6Vp16dR8kuN8RJHPUhRdZ9LIUsS92Oh/WY5GOsI5HaEDDSJ3CaN3hjka6YjmZXwizV8v4ibO92jGINB3StXrw+oc49gxHaE2W+KYbcWWwWZrruu91GBACoIU49TAgdlv5YGphssjQVuoayaehrjJELeIPdgiHKHStZa8NQ71ItnVnPV2i0+IlXtagTkRu2nXDXDDFEFr3qpFGO1fHj1kLBVsQBBUMWGY6i+2hArY0e641xEQa8SoZhgElq1BjVRHqmqNQDQgoBN0uRHvOT1hbahhBO1pq1ntWZY6y6bLVSsEiI1Xnw0tLgvP4jKfsdgbk/6Q/NyI/DKTj8A5rrZUbBhxJ1vawy6vt77RifBrO5F/sBOF/xAlfiuSmVaormYy7h901etYMexY9ysaD384/9uLgbrSqZueATVoaw5BIDgIhhvO3GSyV6y3CsQzQYEHRx0cxud1QFArNDNPVwsPVC0GBY9DO6f2GikM1vCHEVRRXi3GNegHih69457PSTlsBAbDdpoMcvi71QaaE66kMEyY4daBKQMV5djdStAO6po7L1rNFNaMs8oQJKRgb43OgRnr94xfuf7NKoMbsAexU9d+uZaqZgorWkOrCUpt6QC4eg/wFMbUXP8S49a0et4o2YoaV5JLdW4lbRqbnL2CHVWnbs81a6rquAXD3K6L20XQJQXH/ps08FNXf96P6Sf3xpEZ6W+YdeuI2qzpIguWKAuXKHNv1qlT+weDqNlLr3TemevcuDzp/fn7HbQKU+1wWqUt0F7CzVNfk60NgrPtghh6JWw0GKYGW9G9qXiveAfQsAbuEK8RG0ockgzOcTWMoG9NZv/8SdXXjk445HOfORsNuA+w/QOCsQLw1Q4AAA==\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var DefaultAvatarBottomItemStyle = '_1012frs6';
export var DefaultAvatarBottomItemWithAnimationStyle = '_1012frs7';
export var DefaultAvatarContainerStyle = '_1012frs3';
export var DefaultAvatarMiddleItemStyle = '_1012frs4';
export var DefaultAvatarMiddleItemWithAnimationStyle = '_1012frs5';
export var DefaultAvatarTopItemStyle = '_1012frs8';
export var avatarFallback = '_1012frsc';
export var avatarImage = '_1012frsb';
export var avatarRoot = '_1012frs9';
export var avatarWrapper = '_1012frsa';
export var hoverWrapper = '_1012frsd';
export var removeButton = '_1012frse';
export var sizeVar = 'var(--_1012frs0)';